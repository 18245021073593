/** @format */

import React from "react";

const Clients = () => {
  return (
    <div className='rn-brand-area ptb--120 bg_color--1'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
            <div className='section-title text-center mb--30'>
              <span className='subtitle'>Top clients</span>
              <h2 className='title'>We worked with brands.</h2>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12 mt--20'>
            <ul className='brand-style-2' data-aos="fade-up">
              {/* Start Single Brands */}
              <li>
                <img
                  src='assets/clint/Aaron-Jack-INV_300x.png'
                  alt='Logo Images'
                />
              </li>
              {/* End Single Brands */}
              {/* Start Single Brands */}
              <li>
                <img
                  src='assets/clint/Acquisitions-INV_300x.png'
                  alt='Logo Images'
                />
              </li>
              {/* End Single Brands */}
              {/* Start Single Brands */}
              <li>
                <img
                  src='assets/clint/Alexander-Ziskind-INV_300x.png'
                  alt='Logo Images'
                />
              </li>
              {/* End Single Brands */}
              {/* Start Single Brands */}
              <li>
                <img src='assets/clint/Bitget.png' alt='Logo Images' />
              </li>
              {/* End Single Brands */}
              {/* Start Single Brands */}
              <li>
                <img
                  src='assets/clint/BrainBook-INV_300x.png'
                  alt='Logo Images'
                />
              </li>
              {/* End Single Brands */}
              {/* Start Single Brands */}
              <li>
                <img
                  src='assets/clint/Brian-Turner-INV_300x.png'
                  alt='Logo Images'
                />
              </li>
              {/* End Single Brands */}
              {/* Start Single Brands */}
              <li>
                <img src='assets/clint/Cheryl-INV_300x.png' alt='Logo Images' />
              </li>
              {/* End Single Brands */}
              {/* Start Single Brands */}
              <li>
                <img src='assets/clint/Coding Jan.svg' alt='Logo Images' />
              </li>
              {/* End Single Brands */}
              {/* Start Single Brands */}
              <li>
                <img
                  src='assets/clint/Flash Film Academy.png'
                  alt='Logo Images'
                />
              </li>
              {/* End Single Brands */}
              {/* Start Single Brands */}
              <li>
                <img
                  src='assets/clint/Flash FIlm Media.png'
                  alt='Logo Images'
                />
              </li>
              {/* End Single Brands */}
              {/* Start Single Brands */}
              <li>
                <img src='assets/clint/fwr-logo.png' alt='Logo Images' />
              </li>
              {/* End Single Brands */}
              {/* Start Single Brands */}
              <li>
                <img
                  src='assets/clint/Kalle-Hallden-INV_300x.png'
                  alt='Logo Images'
                />
              </li>
              {/* End Single Brands */}
              {/* Start Single Brands */}
              <li>
                <img src='assets/clint/Keaton walker.png' alt='Logo Images' />
              </li>
              {/* End Single Brands */}
              {/* Start Single Brands */}
              <li>
                <img src='assets/clint/steelo-logo-1.png' alt='Logo Images' />
              </li>
              {/* End Single Brands */}
              {/* Start Single Brands */}
              <li>
                <img
                  src='assets/clint/Trevin-Peterson-INV_300x.png'
                  alt='Logo Images'
                />
              </li>
              {/* End Single Brands */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;
